<template>
  <div class="login_layout">
    <div class="login_box">
      <div class="login_page_title">
        <h1>Rcs 后台管理系统</h1>
        <h3>Rcs Management System</h3>
      </div>
      <a-form class="login_input_layout" ref="loginForm" :model="form" :rules="rules">
        <a-form-item label="账号:" prop="account">
          <a-input v-model="form.username"></a-input>
        </a-form-item>
        <a-form-item label="密码:" prop="password">
          <a-input type="password" v-model="form.password" @keyup.enter.native="onSubmit"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button class="login_button" type="primary" @click="onSubmit">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import localStorageUtils from '../../common/local-storage-utils';

export default {
  name: 'Login',
  data() {
    return {
      productList: null,
      form: {
        username: localStorageUtils.getAttribute('username'),
        password: '',
      },
      rules: {},
      allowPost: true,
      count: '',
      timer: null,
    };
  },
  mounted() {
    if (this.$store.state.currentUser) {
      this.$router.push({ path: this.redirect || '/' });
    }
  },
  methods: {
    onSubmit() {
      this.$apiManager.admin
        .login(this.form)
        .then((res) => {
          const userDate = res.data;
          userDate.accessToken = `Bearer ${res.data.accessToken}`;
          this.$store.commit('setUserInfo', userDate);
          this.$router.push({ path: this.redirect || '/' });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 10px;
}

.login_layout {
  color: white;
  background-color: #2c3136;
  flex-direction: row;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login_box {
  display: flex;
  flex-direction: column;
}

.login_input_layout {
  font-size: 0.5rem;
  padding: 1.5rem 2rem 0.5rem;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 0 6px #ccc;
  width: 22rem;
  margin-bottom: 10rem;
}

.login_page_title {
  margin-bottom: 1rem;
  h1 {
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    text-align: center;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 600;
    color: white;
    text-align: center;
  }
}

.login_button {
  width: 100%;
  margin-top: 25px;
}

#get-verification-btn {
  background: #409eff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  margin: 0;
}

#verification-input {
  :nth-child(2) {
    :first-child {
      :nth-child(2) {
        padding: 0;
        border: 1px solid #409eff;
      }
    }
  }
}
</style>
