export default {
  storeData(data, key) {
    if (!window.localStorage || !window.JSON || !key) {
      return;
    }
    localStorage.setItem(key, JSON.stringify(data));
  },

  getData(key) {
    if (!window.localStorage || !window.JSON || !key) {
      return null;
    }
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }
    return JSON.parse(item);
  },
  removeItem(key) {
    if (!window.localStorage || !window.JSON || !key) {
      return;
    }
    localStorage.removeItem(key);
  },
  storeAttribute(attribute, key) {
    if (!window.localStorage || !key) {
      return;
    }
    localStorage.setItem(key, attribute);
  },
  getAttribute(key) {
    if (!window.localStorage || !key) {
      return null;
    }
    return localStorage.getItem(key);
  },
};
